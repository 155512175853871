
 $primary: #021947;
 // text color
 $infoColor: #1F24BF;
 $successColor: #00711E;
 $warningColor: #8A6500;
 $errorColor: #850013;
 // background
 $infoBg: #CCEBFF;
 $successBg: #A7FFD9;
 $warningBg: #FFFFCA;
 $errorBg: #FFC7C1;

 $lightpurpleBg:#D2D3F8;
 $grey: #bdbdbd;
