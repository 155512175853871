$sizes: 1 2 3 4 5 6 7 8 9 10;

@for $size from 1 through 10 {
  $value: ($size * 4) + px;
  .mt-#{$size} {
    margin-top: $value;
  }
  .mr-#{$size} {
    margin-right: $value;
  }
  .mb-#{$size} {
    margin-bottom: $value;
  }
  .ml-#{$size} {
    margin-left: $value;
  }
  .m-#{$size} {
    margin: $value;
  }
}
